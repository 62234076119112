import AppConfig from 'util/AppConfig';

export const INSTANT_ARCHIVING_API_ROUTES = {
  InstantArchivingAPIController: {
    paginated: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/archives' }),
    get: (id: string) => ({ url: `plugins/org.graylog.plugins.storage.server/instant_archiving/archives/${id}` }),
    delete: (archiveId: string, restoredOperationId: string) => ({
      url: `/plugins/org.graylog.plugins.storage.server/instant_archiving/archives/${archiveId}/restored/${restoredOperationId}`,
    }),
    bulk_delete: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/jobs/delete' }),
    bulk_restore: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/jobs/restore' }),
    config: {
      base: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/config' }),
      delete: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/config/delete' }),
      bulk_enable: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/config/set_enabled_status' }),
    },
    estimate: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/size_estimate' }),
    job: {
      progress: () => ({ url: 'plugins/org.graylog.plugins.storage.server/instant_archiving/jobs/progress' }),
      cancel: (id: string) => ({ url: `plugins/org.graylog.plugins.storage.server/instant_archiving/jobs/${id}` }),
    },
  },
};
export const INSTANT_ARCHIVING_CONFIG_QUERY_KEYS = ['instant-archiving', 'config'];

type InstantArchivingSettings = {
  instant_archiving_enabled: boolean
}
const settings: InstantArchivingSettings = AppConfig.pluginUISettings('org.graylog.plugins.storage.instant_archiving');

export const INSTANT_ARCHIVING_ENABLED = settings?.instant_archiving_enabled === true;
