import { INSTANT_ARCHIVING_ENABLED } from 'instant-archiving/Constants';
import { unqualified as UnqualifiedInstantArchivingRoutes } from 'instant-archiving/Routes';
import InstantArchivingsPage from 'instant-archiving/InstantArchivingsPage';
import InstantArchivingConfigPage from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigPage';
import InstantArchiveDetailsPage from 'instant-archiving/InstantArchiveDetailsPage';

const instantArchivingExport = {
  routes: INSTANT_ARCHIVING_ENABLED ? [
    {
      path: UnqualifiedInstantArchivingRoutes.ARCHIVE.LIST,
      component: InstantArchivingsPage,
    },
    {
      path: UnqualifiedInstantArchivingRoutes.ARCHIVE.show(':archiveId'),
      component: InstantArchiveDetailsPage,
    },
    {
      path: UnqualifiedInstantArchivingRoutes.CONFIG,
      component: InstantArchivingConfigPage,
    },
  ] : [],
};

export default instantArchivingExport;
