import AppConfig from 'util/AppConfig';
import { qualifyUrls } from 'routing/Routes';

export const unqualified = {
  ARCHIVE: {
    LIST: '/system/instant-archiving',
    show: (archiveId: string) => `/system/instant-archiving/${archiveId}`,
  },
  CONFIG: '/system/instant-archiving/config',
};

const Routes: typeof unqualified = AppConfig.gl2AppPathPrefix() ? qualifyUrls(unqualified, AppConfig.gl2AppPathPrefix()) : unqualified;

export default Routes;
